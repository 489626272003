/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-04",
    versionChannel: "nightly",
    buildDate: "2024-06-04T00:05:37.409Z",
    commitHash: "6af5a9f7ab0ff36ec49364f95e535ad4c0f833df",
};
